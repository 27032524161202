import Link from 'next/link'
import Image from 'next/future/image'

export default function Custom404 () {
  return (
    <>
      <header className={'header--shadow'}>
        <nav className={'navbar navbar-expand-xl bg-white}'}>
          <div className="container">
            <Link href="/">
              <a
                className="navbar-brand"
                href="#"
              >
                <Image
                  src="/images/logo.svg"
                  width={150}
                  height={30}
                  alt=""
                />
              </a>
            </Link>
          </div>
        </nav>
      </header>
      <main>
        <div className={'container text-sm-center'}>
          <h1 className={'my-5'}>404 - Not Found</h1>
        </div>
      </main>
      <footer className={'pagefooter__bgimage py-5'}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-8 col-lg-5 mb-4 mb-md-0">
              <span className="h2 fw-bold text-white d-block">CADSOMA</span>
            </div>
            <div className="col-12 col-md-4 col-lg-6">
              <nav>
                <ul className="row list-unstyled fs-6 lh-lg">
                  <strong className="text-white text-uppercase mb-3">CADSOMA.com</strong>
                  <li className="nav-item col-6 col-md-12 col-lg-6">
                    <Link href="/about">
                      <a className="nav-link text-white">About</a>
                    </Link>
                  </li>
                  <li className="nav-item col-6 col-md-12 col-lg-6">
                    <a
                      href="https://cad-deutschland.de"
                      className="nav-link text-white"
                      target="_blank"
                      rel="noreferrer"
                    >CAD Deutschalnd</a>
                  </li>
                  <li className="nav-item col-6 col-md-12 col-lg-6">
                    <a
                      target="_blank"
                      href="https://www.mervisoft-gmbh.de/"
                      className="nav-link text-white"
                      rel="noreferrer"
                    >MERViSOFT GmbH</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className={'pagefooter_separator my-3 mb-4 my-lg-5'}></div>
          <div className="row justify-content-between">
            <div className="col-6 col-lg-4 order-lg-3">
              <div className="row">
                <strong className="text-white col-12 text-uppercase fs-6 mb-3">Service</strong>
              </div>
              <nav className="row">
                <ul className="fs-6 lh-lg col-12 d-lg-flex gap-3">
                  <li className="nav-item">
                    <Link href="/privacy-policy">
                      <a className="nav-link text-white">Privacy Policy</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="/imprint">
                      <a className="nav-link text-white">Imprint</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="/terms-and-conditions-seller">
                      <a className="nav-link text-white">TOS Seller</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="/terms-and-conditions-user">
                      <a className="nav-link text-white">TOS User</a>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-12 col-lg-5 order-lg-2">
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
